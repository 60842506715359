import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import { scrollTo } from './lib/helpers';
import Animator from './lib/Animator';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();
    Animator.init();

    // Anchor links
    $('body').on('click', 'a[href^="#"]:not([data-noanchor])', e => {
        const targetId = $(e.triggerTarget).attr('href').slice(1);
        const target = targetId ? $(`#${targetId}`).get(0) : null;
        if (!target) {
            return true;
        }
        e.preventDefault();
        scrollTo(target);
        return false;
    });
};

require('doc-ready')(init);
