import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Headroom from "headroom.js";
import gsap from "gsap";

export default el => {

    const $el = $(el);
    const $bar = $el.find('[data-header-bar]');
    const $menu = $el.find('#menu');
    const $menuToggle = $el.find('[data-menu-toggle]');
    const $menuPanel = $el.find('[data-menu-panel]');
    const $menuPanelContents = $el.find('[data-menu-panel-contents]');
    const $menuBg = $el.find('[data-menu-bg]');
    const $menuClose = $el.find('[data-menu-toggle="close"]');
    const $menuToggleLines = $el.find('[data-menu-toggle="close"] span');

    let isOpen = false;
    let focusedElement = null;
    let headroom = null;

    /**
     * Whitelist classes: headroom headroom--pinned headroom--unpinned headroom--initial headroom--scrolled headroom--top headroom--not-top headroom--bottom headroom--not-bottom
     */

    const init = () => {
        $menuToggle.attr({
            tabIndex: '0',
            role: 'button',
            'aria-expanded': 'false'
        }).on('click', e => {
            e.preventDefault();
            toggleMenu(false);
        }).on('keydown', e => {
            const key = e.key || e.keyCode || e.which || null;
            if (['Enter', 13].indexOf(key) > -1) {
                e.preventDefault();
                toggleMenu(false);
            }
        }).get(0).removeAttribute('href');


        $el.find('[data-accordion]').on('click', 'button', e => {
            e.preventDefault();
            toggleAccordion($(e.delegateTarget), false);
        });

        $menuBg.on('click', () => {
            closeMenu();
        });

        headroom = new Headroom($bar.get(0), {
            offset: 50,
            tolerance: 10,
        });
        headroom.init();

        $bar.on('focusin', () => {
            headroom.pin();
        });

        document.addEventListener('keyup', onKeyUp);

        gsap.set($menuBg.get(0), { opacity: 0 });

        // Account for the menu being opened already before the JS had the chance to boot
        const navId = $menuToggle.attr('href');

        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                window.location.hash = '';
                openMenu(true);
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        });
    };

    const destroy = () => {
        if (headroom) {
            headroom.destroy();
        }

        $el.off('click');
        $menuToggle.off('click');
        $el.find('[data-accordion]').off('click');
        $bar.off('focusin');
        $menuBg.off('click');
        document.removeEventListener('keyup', onKeyUp);
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if (isOpen && key === 27) {
            closeMenu();
        }
    };

    const toggleMenu = force => {
        if (!isOpen) {
            openMenu(force);
        } else {
            closeMenu(force);
        }
    };

    const openMenu = force => {
        $menu.addClass('flex');

        gsap.set($menuBg.get(0), { opacity: 0 });
        gsap.set($menuPanel.get(0), { x: $menuPanel.width() });
        gsap.set($menuPanelContents.get(0), { x: 100, opacity: 0 });
        gsap.set($menuToggleLines.get(0), { width: 0 });
        gsap.set($menuToggleLines.get(1), { height: 0 });

        gsap.to($menuBg.get(0), { duration: 0.6, opacity: 1 });
        gsap.to($menuPanel.get(0), { duration: 0.6, x: 0, ease: 'quart.out' });
        gsap.to($menuPanelContents.get(0), { duration: 1, delay: 0.2, opacity: 1 });
        gsap.to($menuPanelContents.get(0), { duration: 1.4, delay: 0.2, x: 0, ease: 'expo.out' });

        gsap.to($menuToggleLines.get(0), { duration: 0.5, delay: 0.4, width: 35, ease: 'quint.out' });
        gsap.to($menuToggleLines.get(1), { duration: 0.5, delay: 0.5, height: 35, ease: 'quint.out' });

        $menuToggle.get(0).setAttribute('aria-expanded', 'true');
        focusedElement = document.activeElement || null;
        Viewport.lockTabbing($menu.get(0), $menuClose.get(0));

        isOpen = true;
    };

    const closeMenu = force => {
        gsap.to($menuPanel.get(0), { duration: 0.3, x: $menuPanel.width() + 30, ease: 'sine.in' });
        gsap.to($menuPanelContents.get(0), { duration: 0.15, opacity: 0 });

        gsap.to($menuToggleLines.get(0), { duration: 0.20, width: 0, ease: 'quint.out' });
        gsap.to($menuToggleLines.get(1), { duration: 0.20, delay: 0.075, height: 0, ease: 'quint.out' });

        gsap.to($menuBg.get(0), {
            duration: 0.4, opacity: 0, onComplete: () => {
                $menu.removeClass('flex');
                isOpen = false;
            }
        });

        Viewport.releaseTabbing(focusedElement);
        focusedElement = null;
        $menuToggle.get(0).setAttribute('aria-expanded', 'false');
    };

    const toggleAccordion = ($accordion, force) => {
        const $button = $accordion.find('button');
        const $list = $accordion.find('ul');

        if ($button.attr('aria-expanded') === 'false') {
            $list.css({ display: 'block' });
            $button.attr('aria-expanded', 'true');
        } else {
            $list.css({ display: 'none' });
            $button.attr('aria-expanded', 'false');
        }
    };


    return {
        init,
        destroy
    };

};
