import gsap from 'gsap';
import $ from '../core/Dom';
import Flickity from 'flickity';

export default el => {

    const $el = $(el);
    const $slideWrapper = $el.find('[data-slides-wrapper]');
    const $slides = $el.find('[data-slide]');
    
    const $prevBtn = $el.find('[data-button-prev]');
    const $nextBtn = $el.find('[data-button-next]');
    
    let flkty = null;
    
    const init = () => {
        $slides.each(item => {
            const $item = $(item);
            const $description = $item.find('[data-slide-description]')
            $description.width(1);
            const targetWidth = $item.width();
            $description.width(targetWidth);
        });
        
        $slideWrapper.removeClass('flex');
        
        flkty = new Flickity($slideWrapper.get(0), {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            wrapAround: false,
            freeScroll: true,
            freeScrollFriction: 0.045
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });
        
        flkty.on('change', index => {
            updateButtons();
        });
        
        flkty.on('select', index => {
            updateButtons();
        });
        
        $prevBtn.on('click', e => {
            prev();
        });
        $nextBtn.on('click', e => {
            next();
        });
        
        updateButtons();
    };

    const destroy = () => {

    };

    const prev = () => {
        if (flkty) {
            flkty.previous();
        }
    };
    
    const next = () => {
        if (flkty) {
            flkty.next();
        }
    };
    
    const updateButtons = () => {
        $prevBtn.removeClass('text-dark-green-50').attr('disabled', null);
        $nextBtn.removeClass('text-dark-green-50').attr('disabled', null);
        
        if (flkty && flkty.selectedIndex === 0) {
            $prevBtn.addClass('text-dark-green-50').attr('disabled', '');
        }
        
        if (flkty && flkty.selectedIndex >= flkty.slides.length - 1) {
            $nextBtn.addClass('text-dark-green-50').attr('disabled', '');
        }
    };
    
    return {
        init,
        destroy
    };

};
