import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';


export default el => {
    const $el = $(el);
    const $heroText = $el.find('[data-hero-heading]');
    const $heroImageWrap = $el.find('[data-hero-image-wrap]');

    let animationId = null;
    
    const init = () => {
        $heroText.css({ opacity: 1 });
        gsap.from($heroText.nodes, { delay: 0.8, duration: 1.4, y: Viewport.height / 25, ease: "quint.out" });
        gsap.from($heroText.nodes, { delay: 0.8, duration: 1.1, autoAlpha: 0, ease: "none" });

        start();
    };

    const destroy = () => {
        if (animationId !== null) {
            stop();
        }
    };

    const parallax = () => {
        animationId = requestAnimationFrame(parallax);
        const top = window.pageYOffset || document.documentElement.scrollTop;
        gsap.set($heroImageWrap.nodes, { y: top/4 });
    };

    const start = () => {
        if (animationId !== null) {
            cancelAnimationFrame(animationId);
            animationId = null;
        }

        animationId = requestAnimationFrame(parallax);
    };

    const stop = () => {
        cancelAnimationFrame(animationId);
        animationId = null;
    };


    return {
        init,
        destroy
    };

};
