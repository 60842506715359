import $ from '../core/Dom';
import superagent from '../core/request';
import Components from '../core/Components';
import Animator from '../lib/Animator';
import i18n from '../lib/i18n';

export default el => {

    const $el = $(el);
    const $itemsContainer = $el.find('[data-item]').parent().eq(0);
    const $loadmoreButton = $el.find('button[data-loadmore]').eq(0);
    const $announcer = $el.find('[data-announcer]').eq(0);

    let nextUrl = $loadmoreButton.data('loadmore');

    if (!nextUrl) {
        return null;
    }

    let isLoadingMore = false;

    const addMore = html => {
        const $newHtml = $(`<div>${html}</div>`).find(`#${el.id}`).eq(0);
        const $newItems = $newHtml.find('[data-item]');
        if ($newItems.length) {
            $newItems.find('noscript').remove();
            const currentCount = $itemsContainer.find('[data-item]').length;
            $itemsContainer.append($newItems);
            Components.init($itemsContainer.get(0));
            if ($('html').hasClass('outline')) {
                $itemsContainer.find('[data-item]').eq(currentCount).find('a').focus();
            }
            $announcer.text(i18n('Added {count} stories', { count: $newItems.length }));
            Animator.addElements($newItems);
        }
        nextUrl = $newHtml.find('button[data-loadmore]').data('loadmore');
        
        if (!nextUrl) {
            $loadmoreButton.attr('aria-disabled', 'true');
            $loadmoreButton.off('click').css({ cursor: 'default' });
        }
    };

    const loadMore = () => {
        if (isLoadingMore) {
            return;
        }
        isLoadingMore = true;
        $announcer.text(i18n('Loading more stories'));
        superagent
            .get(nextUrl)
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                addMore(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoadingMore = false;
            });
    };

    const init = () => {
        $loadmoreButton.on('click', loadMore);
    };

    const destroy = () => {
        $loadmoreButton.off('click');
    };

    return {
        init,
        destroy
    };

};
