import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
//import SplitText from 'gsap/SplitText'

export default el => {
    const $el = $(el);
    const $heroText = $el.find('[data-hero-text]');
    const $heroButton = $el.find('[data-hero-button]');
    const $heroImageWrap = $el.find('[data-hero-image-wrap]');

    let split = null;
    let animationId = null;
    let originalContent = '';

    const init = () => {
        originalContent = $heroText.html();

        //makeSplit();
        Viewport.on('resize', onResize);

        //gsap.from(split.lines, { delay: 1, duration: 1.4, y: Viewport.height / 25, autoAlpha: 0, ease: "quart.out", stagger: 0.2 });
        $heroText.css({ opacity: 1 });
        gsap.from($heroText.nodes, { delay: 1, duration: 1.4, y: Viewport.height / 25, autoAlpha: 0, ease: "quart.out", stagger: 0.2 });
        gsap.to($heroButton.get(0), { delay: 1.8, duration: 0.6, opacity: 1 });

        start();
    };

    const destroy = () => {
        if (animationId !== null) {
            stop();
        }

        Viewport.off('resize', onResize);
    };

    const onResize = () => {
        //makeSplit();
    };
/*
    const makeSplit = () => {
        if (split !== null) {
            split.revert();
        }

        let linesWithShy = [];
        let linesWithoutShy = [];

        split = new SplitText($heroText.get(0), { type: 'lines' });

        split.lines.forEach(line => {
            linesWithShy.push(line.textContent.trim());
        });

        split.revert();

        $heroText.text(originalContent.replace(/(\&shy;|­|&#173;)/gi, "- "));

        split = new SplitText($heroText.get(0), { type: 'lines' });

        split.lines.forEach(line => {
            linesWithoutShy.push(line.textContent.trim());
        });

        split.revert();
        
        if (shouldUseHardHyphen(linesWithShy, linesWithoutShy)) {
            $heroText.text(originalContent.replace(/(\&shy;|­|&#173;)/gi, "- "));
        } else {
            $heroText.text(originalContent);
        }
        
        split = new SplitText($heroText.get(0), { type: 'lines' });
    };
*/
    const parallax = () => {
        animationId = requestAnimationFrame(parallax);
        const top = window.pageYOffset || document.documentElement.scrollTop;
        gsap.set($heroImageWrap.nodes, { y: top / 4 });
    };

    const start = () => {
        if (animationId !== null) {
            cancelAnimationFrame(animationId);
            animationId = null;
        }

        animationId = requestAnimationFrame(parallax);
    };

    const stop = () => {
        cancelAnimationFrame(animationId);
        animationId = null;
    };

    const shouldUseHardHyphen = (withShy, withoutShy) => {
        if (withShy.length !== withoutShy.length) {
            return true;
        }

        for (let i = 0; i < withShy.length; i++) {
            if (withShy[i].replace(/(\&shy;|­|&#173;)/gi, "") !== withoutShy[i].replace("- ", "")) {
                return true;
            }
        }
        
        return false;
    };

    return {
        init,
        destroy
    };

};
