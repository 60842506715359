import $ from '../core/Dom';
import superagent from '../core/request';
import Components from '../core/Components';
import Animator from '../lib/Animator';
import i18n from '../lib/i18n';

export default el => {

    const $el = $(el);
    const $itemsContainer = $el.find('[data-list-wrapper]');
    
    let isLoading = false;

    const addContent = html => {
        const $newHtml = $(`<div>${html}</div>`).eq(0);
        const $newItems = $newHtml.find('[data-item]');
        const $categoryFilter = $newHtml.find('[data-category-filter]');
        
        $itemsContainer.empty();
        
        if ($newItems.length) {
            $newItems.find('noscript').remove();

            const currentCount = $itemsContainer.find('[data-item]').length;
            $itemsContainer.append($newItems);
            Components.init($itemsContainer.get(0));

            if ($('html').hasClass('outline')) {
                $itemsContainer.find('[data-item]').eq(currentCount).find('a').focus();
            }

            Animator.addElements($newItems);
        }
        
        if ($categoryFilter.length) {
            $el.find('[data-category-filter]').empty().append($categoryFilter.find('li'));
        }
    };

    const load = e => {
        const $triggerTarget = $(e.triggerTarget);
        const href = $triggerTarget.attr('href') || null;
        
        if (!href) {
            return;
        }
        
        if (isLoading) {
            return;
        }
        
        e.preventDefault();
        isLoading = true;
        
        superagent
            .get(href)
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                addContent(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });
    };

    const init = () => {
        $el.on('click', '[data-category-filter-item]', load);
    };

    const destroy = () => {
        $el.off('click', '[data-category-filter-item]', load);
    };

    return {
        init,
        destroy
    };

};
