import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { VIEWPORTHEIGHT_CHANGE } from '../lib/events';

export default el => {

    const $el = $(el);
    const $main = $('#main');

    let viewportW = null;
    let viewportH = null;
    let isSticky = $el.hasClass('sticky');

    const maybeMakeSticky = () => {
        if (!viewportW || !viewportH) {
            return;
        }
        // Make the footer sticky if it's not taller than the viewport, but <main> is
        const maxHeight = viewportH - 150;
        const makeSticky = $main.height() > maxHeight && $el.height() < maxHeight;
        if (makeSticky === isSticky) {
            return;
        }
        if (makeSticky) {
            $el.addClass('sticky');
        } else {
            $el.removeClass('sticky');
        }
        isSticky = makeSticky;
    };

    const onResize = () => {
        if (Viewport.width === viewportW) {
            return;
        }
        viewportW = Viewport.width;
        maybeMakeSticky();
    };

    const onViewportHeightChange = () => {
        if (Viewport.height === viewportH) {
            return;
        }
        viewportH = Viewport.height;
        maybeMakeSticky();
    };

    const onInnerFocus = () => {
        const { scrollTop } = Viewport;
        const offsetTop = $el.offset().top;
        if (scrollTop < offsetTop) {
            Viewport.scrollTo(el, { duration: 0 });
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        Dispatch.on(VIEWPORTHEIGHT_CHANGE, onViewportHeightChange, true);
        $el.on('focusin', 'a,button', onInnerFocus);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Dispatch.off(VIEWPORTHEIGHT_CHANGE, onViewportHeightChange);
        $el.off('focusin');
    };

    return {
        init,
        destroy
    };

};
