import $ from '../core/Dom';
import superagent from '../core/request';
import Components from '../core/Components';
import Dispatch from '../core/Dispatch';
import Animator from '../lib/Animator';
import gsap from 'gsap';

import serialize from 'form-serialize';

import { DOM_UPDATED, GRID_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $form = $el.find('form');
    const $resultWrapper = $el.find('[data-result-wrapper]');
    const $paginationWrapper = $el.find('[data-pagination-wrapper]');
    const $searchTermsWrapper = $el.find('[data-search-terms-wrapper]');
    const $closeWrapper = $el.find('[data-close-wrapper]');
    
    const { indexPageUrl } = props;

    let isLoading = false;
    let currentViewMode = '';
    let nextViewMode = '';
    let hasUpdatedHistory = false;

    const init = () => {
        $form.on('change', '[data-view-toggle]', e => {
            updateSearch();
        });

        $form.on('change', '[data-filter-select]', e => {
            updateSearch();
        });

        $form.on('submit', e => {
            e.preventDefault();
            updateSearch();
        });
        
        $paginationWrapper.on('click', 'a', e => {
            e.preventDefault();
            loadNextPage();
        });
        
        $searchTermsWrapper.on('click', 'button', e => {
            e.preventDefault();
            removeTerm($(e.triggerTarget));
        });
        
        $closeWrapper.removeClass('hidden').css({ opacity: 0 });
        checkClose();
        
        window.addEventListener('popstate', e => {
            const { state } = e;
            window.location.reload();
        });
    };

    const destroy = () => {
        $form.off('submit');
    };

    const updateSearch = () => {
        if (isLoading) {
            return;
        }

        isLoading = true;
        
        const query = serialize($form.get(0), { hash: true });
        const queryStr = serialize($form.get(0), { hash: false });
        gsap.to([$resultWrapper.get(0), $searchTermsWrapper.get(0)], { duration: 0.2, opacity: 0.3 });
        nextViewMode = query.view;

        superagent
            .get(indexPageUrl)
            .query(query)
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                insertData(text, false);
                
                if (window.history) {
                    const newUrl = indexPageUrl + (queryStr !== '' ? '?' + queryStr : '');
                    
                    if (hasUpdatedHistory) {
                        window.history.replaceState(null, document.title, newUrl);
                    } else {
                        window.history.pushState(null, document.title, newUrl);
                    }
                    
                    hasUpdatedHistory = true;
                }
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });
    };
    
    const loadNextPage = () => {
        if (isLoading) {
            return;
        }

        isLoading = true;
        
        const url = $paginationWrapper.find('a').attr('href');
        
        gsap.to([$resultWrapper.get(0), $searchTermsWrapper.get(0)], { duration: 0.2, opacity: 0.5 });

        superagent
            .get(url)
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                insertData(text, true);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
            });        
    };

    const insertData = (html, append) => {
        const $newHtml = $(`<div>${html}</div>`);
        $newHtml.find('noscript').remove();
        
        const $newResultWrapperContents = $newHtml.find('[data-result-wrapper] > *');
        const $newResults = $newHtml.find('[data-result-wrapper-inner] > *');
        const $newPagination = $newHtml.find('[data-pagination-wrapper]');
        const $newSearchTermWrapper = $newHtml.find('[data-search-terms-wrapper]');
        
        if (nextViewMode !== currentViewMode) {
            Components.destroy($resultWrapper.get(0));
            $resultWrapper.empty()
            gsap.set($resultWrapper.get(0), { opacity: 1 });
            $resultWrapper.append($newResultWrapperContents);
            Components.init($resultWrapper.get(0));
            Animator.addElements($resultWrapper);
        
        } else {
            if ($newResults.length) {
                //Components.destroy($resultWrapper.get(0));
                gsap.set($resultWrapper.get(0), { opacity: 1 });
                
                if (!append) {
                    $resultWrapper.find('[data-result-wrapper-inner]').empty();
                }
    
                $resultWrapper.find('[data-result-wrapper-inner]').append($newResults);
                Dispatch.emit(GRID_UPDATED);
                
                //Components.init($resultWrapper.get(0));
                Animator.addElements($newResults);
            } else {
                $resultWrapper.find('[data-result-wrapper-inner]').empty();
            }
        }
        
        currentViewMode = nextViewMode;
        
        checkClose();
        
        // todo : show no items message/result
                
        if ($newPagination.length) {
            $paginationWrapper.empty().append($newPagination.get(0).children)
        }
        if ($newSearchTermWrapper.length) {
            $searchTermsWrapper.empty().append($newSearchTermWrapper.get(0).children)
        }
        
        gsap.to($searchTermsWrapper.get(0), { duration: 0.2, opacity: 1 });
    };
    
    const removeTerm = $target => {
        const term = $target.data('term');
        console.log(term);
        
        if (term === 'q') {
            $form.find('input[name="q"]').val('');
            updateSearch();
        } else {
            $form.find('select[name="' + term + '"]').get(0).selectedIndex = 0;
            updateSearch();
        }
        
    };
    
    const checkClose = () => {
        const shouldShow = serialize($form.get(0)) !== '';
        gsap.to($closeWrapper.get(0), { duration: 0, delay: 0, opacity: shouldShow ? 1 : 0 })
    };

    return {
        init,
        destroy
    };

};
