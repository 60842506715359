/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const FORCE_VIDEO_RESIZE = 'FORCE_VIDEO_RESIZE';
export const MENU_OPEN = 'MENU_OPEN';
export const BG_COLOR_CHANGE = 'BG_COLOR_CHANGE';
export const VIEWPORTHEIGHT_CHANGE = 'VIEWPORTHEIGHT_CHANGE';
export const DOM_UPDATED = 'DOM_UPDATED';
export const GRID_UPDATED = 'GRID_UPDATED';

