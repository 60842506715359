import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

let isScrollingTo;

export const reduceMotion = () => window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)').matches;

export const scrollTo = (value, opts = {}) => {

    if (isScrollingTo === value) {
        return;
    }

    isScrollingTo = value;

    let scrollMargin;

    try {
        scrollMargin = parseInt(window.getComputedStyle(value).getPropertyValue('scroll-margin-top') || 0, 10);
    } catch (error) {
        scrollMargin = 0;
    }

    const duration = reduceMotion() ? 0 : 1;

    gsap.to(window, {
        duration,
        ease: 'Cubic.easeInOut',
        ...opts,
        scrollTo: {
            y: value,
            offsetY: scrollMargin,
            autoKill: true,
            onAutoKill() {
                isScrollingTo = null;
            }
        },
        onComplete() {
            isScrollingTo = null;
        }
    });
};
