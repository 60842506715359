import $ from '../core/Dom';
import superagent from '../core/request';
import Components from '../core/Components';
import i18n from '../lib/i18n';

export default el => {
    const $el = $(el);
    const $overlay = $el.find('[data-company-overlay]');
    const $overlayCloseBtn = $el.find('[data-company-overlay-close]');
    const $overlayLoader = $el.find('[data-company-overlay-loader]');
    const $overlayContent = $el.find('[data-company-overlay-content]');
    
    let isLoading = false;
    
    const init = () => {
        $el.on('click', '[data-company-teaser-trigger]', e => {
            e.preventDefault();
            if (!isLoading) {
                openOverlay($(e.triggerTarget).attr('href'));
            }
        });
        
        $overlayCloseBtn.on('click', e => {
            closeOverlay();
        });
        
        document.addEventListener('keyup', onKeyUp);
    };

    const destroy = () => {
        $el.off('click');
        $overlayCloseBtn.off('click');
        document.removeEventListener('keyup', onKeyUp);
    };
    
    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if (key === 27) {
            closeOverlay();
        }
    };
    
    const openOverlay = url => {
        console.log(url);
        $overlay.css({ display: 'block' });
        
        isLoading = true;
        $overlayLoader.css({ display: 'block' });
        $overlayCloseBtn.css({ display: 'none' });

        superagent
            .get(url)
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                addContent(text);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                isLoading = false;
                $overlayLoader.css({ display: '' });
                $overlayCloseBtn.css({ display: '' });
            });
    };
    
    const addContent = html => {
        const $newHtml = $(`<div>${html}</div>`);
        const $newContent = $newHtml.find('[data-company-content]');
        if ($newContent.length) {
            $newContent.find('noscript').remove();
            $overlayContent.append($newContent);
            Components.init($overlayContent.get(0));
        }
    };
    
    const closeOverlay = () => {
        $overlay.css({ display: 'none' });
        $overlayContent.empty();
    };

    return {
        init,
        destroy
    };

};
