import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $toggleButton = $el.find('[data-expand-button]');
    const $expandable = $el.find('[data-expandable');

    let isExpanded = false;

    const init = () => {
        $toggleButton.on('click', onToggle);
    };

    const destroy = () => {
        $toggleButton.off('click');
    };

    const onToggle = e => {
        e.preventDefault();
        if (isExpanded) {
            gsap.to($expandable.get(0), {
                duration: 0.1, opacity: 0, onComplete: () => {
                    gsap.to($expandable.get(0), {
                        duration: 0.6, ease: 'quart.out', height: 0, onComplete: () => {
                            gsap.set($expandable.get(0), { height: '', opacity: '', display: '' });
                            gsap.to($expandable.get(0), { duration: 0.4, opacity: 1 });
                            
                        }
                    });
                }
            });

            $toggleButton.attr('aria-expanded', 'false');
            isExpanded = false;
        } else {
            $expandable.css({ display: 'block' });
            const targetHeight = $expandable.height();

            gsap.set($expandable.get(0), { height: 0, opacity: 0 });
            gsap.to($expandable.get(0), {
                duration: 0.5, ease: 'quart.out', height: targetHeight, onComplete: () => {
                    gsap.set($expandable.get(0), { height: '' });
                    gsap.to($expandable.get(0), { duration: 0.5, opacity: 1 });
                }
            });

            $toggleButton.attr('aria-expanded', 'true');
            isExpanded = true;
        }
    };

    return {
        init, destroy
    };
};
